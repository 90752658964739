import './FeedbackForm.css';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import SubmitPopup from './SubmitPopup/SubmitPopup';

function FeedbackForm() {
  const { t } = useTranslation();

  const [isPopupSubmitFormOpen, setIsPopupSubmitFormOpen] = useState(false);
  
  const handleButtonOpenPopup = (e) => {
    setIsPopupSubmitFormOpen(true)
  }

  const closeSubmitFormPopup = () => {
    setIsPopupSubmitFormOpen(false)
  }

  const { 
    register, handleSubmit, formState: { errors }, watch, setValue, reset
  } = useForm({
    mode: "onChange"
  });

  const watchName = watch('name')
  const watchEmail = watch('email')

  const handleButtonNameClick = () => {
    setValue('name', '')
  }

  const handleButtonEmailClick = () => {
    setValue('email', '')
  }

  const onSubmit = (data) => {
    fetch('/feedback', {
      method: 'POST',
      body: JSON.stringify({
        email: data.email,
        name: data.name
      }),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(
        r => {
          handleButtonOpenPopup()
          reset()
        }
    )
    
  }

  return (
    <>
      <section className='feedback-form'>
        <span className='feedback-form__object feedback-form__object_left'/>
        <span className='feedback-form__object feedback-form__object_right'/>
        <div className='feedback-form__wrapper'>
          <h2 className='feedback-form__title'>{t("form.title")}</h2>
          <p className='feedback-form__subtitle'>{t("form.subtitle")}</p>
          <form className='feedback-form__form' onSubmit={handleSubmit(onSubmit)}>
            <label className='feedback-form__label'>
              <input
                className={`feedback-form__input ${errors?.name && 'feedback-form__input_invalid'}`}
                placeholder={t("form.namePlaceholder")}
                type='text'
                {...register('name', {
                  required: t("form.requiredError"),
                })}
              />
              <button
                className={`${watchName === '' || watchName === undefined ? 'feedback-form__delete-button_invisible' : 'feedback-form__delete-button'}`}
                type='button'
                onClick={handleButtonNameClick}
              />
              {errors?.name && (
                <div className='feedback-form__error'>
                  {errors.name.message}
                </div>
              )}
            </label>
            <label className='feedback-form__label'>
              <input
                className={`feedback-form__input ${errors?.email && 'feedback-form__input_invalid'}`}
                placeholder={t("form.emailPlaceholder")}
                type='email'
                {...register('email', {
                  required: t("form.requiredError"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("form.wrongEmail")
                  }
                })}
              />
              <button
                className={`${watchEmail === '' || watchName === undefined ? 'feedback-form__delete-button_invisible' : 'feedback-form__delete-button'}`}
                type='button'
                onClick={handleButtonEmailClick}
              />
              {errors?.email && (
                <div className='feedback-form__error'>
                  {errors.email.message}
                </div>
              )}
            </label>
            <label className='feedback-form__agreement'>
              <input
                type='checkbox'
                className='feedback-form__checkbox_invisible'
                {...register('confirmation', {
                  required: t("form.confirmError"),
                })}
              />
              <span className='feedback-form__checkbox_visible'/>
              <p className='filter-checkbox__title'>{t("form.confirmAgreement")}</p>
              {errors?.confirmation && (
                <div className='feedback-form__error feedback-form__error_agreement'>
                  {errors.confirmation.message}
                </div>
              )}
            </label>
            <button className='feedback-form__button' type='submit'>{t("form.submitButton")}</button>
          </form>
        </div>
      </section>
      <SubmitPopup onClose={closeSubmitFormPopup} isOpen={isPopupSubmitFormOpen}/>
    </>
  )
}

export default FeedbackForm;