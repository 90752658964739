import './Projects.css';
import SliderBlock from './SliderBlock/SliderBlock';
import { useTranslation } from "react-i18next";

function Projects() {
  const { t } = useTranslation();

  return (
    <section className='projects section_projects'>
      <h2 className='projects__title'>{t("projects.title")}</h2>
      <div className='projects__slider-container'>
        <SliderBlock />
      </div>
    </section>
  )
}

export default Projects;