import './SliderItem.css';

function SliderItem ({project, openPopup, setInitialProject}) {

  const handleProjectClick = () => {
    openPopup()
    setInitialProject(project)
  }

  return (
    <div className='projects-slider__item' onClick={handleProjectClick}>
      {/*<img src={project.image} className='projects-slider__item-img' alt={project.alt}/>*/}
      <h3 className='projects-slider__item-title'>{project.title}</h3>
      <p className='projects-slider__item-subtitle'>{project.subtitle}</p>
      <div className='projects-slider__item-container'>
        <ul className='projects-slider__item-tags'>
          {project.tags.map((tag, index) =>
            <li key={index} className='projects-slider__item-tag'>
              {tag}
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default SliderItem;