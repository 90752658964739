import './About.css';
import logo from '../../images/Logo.png'

import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  const hadleButtonStartProjectClick = () => {
    document.querySelector('.feedback-form').scrollIntoView( { behavior: "smooth"})
  }

  return (
    <section className='about'>
      <div className='about__container'>
        <div className='about__logo'>
          <img src={logo} className='about__logo-image' alt={t("about.alt")}/>
          <h1 className='about__title'>{t("about.title")}</h1>
        </div>
        <h2 className='about__subtitle'>{t("about.subtitle")}</h2>
        <button className='about__button' onClick={hadleButtonStartProjectClick}>{t("about.button")}</button>
      </div>
      <div className='about__image'/>
    </section>
  )
}

export default About;