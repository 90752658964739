import './Technologies.css';
import { useTranslation } from "react-i18next";

function Technologies() {
  const { t } = useTranslation();

  const hadleButtonStartProjectClick = () => {
    document.querySelector('.feedback-form').scrollIntoView( { behavior: "smooth"})
  }

  return (
    <section className='technologies section_technology'>
      <h2 className='technologies__title'>{t("technologies.title")}</h2>
      <p className='technologies__subtitle'>{t("technologies.subtitle")}</p>
      <ul className='technologies__table'>
        <li className='technologies__table-cell'>
          <h3 className='technologies__table-title'>{t("technologies.backend")}</h3>
          <p className='technologies__table-subtitle'>{t("technologies.backendSubtitle")}</p>
        </li>
        <li className='technologies__table-cell'>
          <h3 className='technologies__table-title'>{t("technologies.databases")}</h3>
          <p className='technologies__table-subtitle'>{t("technologies.databasesSubtitle")}</p>
        </li>
        <li className='technologies__table-cell'>
          <h3 className='technologies__table-title'>{t("technologies.web3")}</h3>
          <p className='technologies__table-subtitle'>{t("technologies.web3Subtitle")}</p>
        </li>
        <li className='technologies__table-cell'>
          <h3 className='technologies__table-title'>{t("technologies.frontend")}</h3>
          <p className='technologies__table-subtitle'>{t("technologies.frontendSubtitle")}</p>
        </li>
        <li className='technologies__table-cell'>
          <h3 className='technologies__table-title'>{t("technologies.mobileApps")}</h3>
          <p className='technologies__table-subtitle'>{t("technologies.mobileAppsSubtitle")}</p>
        </li>
        <li className='technologies__table-cell'>
          <h3 className='technologies__table-title'>{t("technologies.tools")}</h3>
          <p className='technologies__table-subtitle'>{t("technologies.toolsSubtitle")}</p>
        </li>
        <li className='technologies__table-cell technologies__table-cell_last-row'>
          <h3 className='technologies__table-title'>{t("technologies.testing")}</h3>
          <p className='technologies__table-subtitle'>{t("technologies.testingSubtitle")}</p>
        </li>
        <li className='technologies__table-cell technologies__table-cell_last-row'>
          <h3 className='technologies__table-title'>{t("technologies.sap")}</h3>
          <p className='technologies__table-subtitle'>{t("technologies.sapSubtitle")}</p>
        </li>
      </ul>
      <button className='technologies__button' onClick={hadleButtonStartProjectClick}>{t("technologies.button")}</button>
    </section>
  )
}

export default Technologies;