import './ProjectTeam.css';
import team from '../../images/team.png';
import georgia from '../../images/Georgia.png';
import ukraine from '../../images/Ukraine.png';
import serbia from '../../images/Serbia.png';
import poland from '../../images/Poland.png';
import russia from '../../images/Russia.png';
import belarus from '../../images/Belarus.png';
import thailand from '../../images/Thailand.png';
import { useTranslation } from "react-i18next";

function ProjectTeam() {
  const { t } = useTranslation();

  return (
    <section className='project-team section_team'>
      <img src={team} className='project-team__image' alt={t("team.imageAlt")}/>
      <div className='project-team__container'>
        <h2 className='project-team__title'>{t("team.title")}</h2>
        <p className='project-team__subtitle'>
        {t("team.subtitle")}
        </p>
        <ul className='project-team__countries'>
          <li className='project-team__country'>
            <img className='project-team__country-icon' src={georgia} alt={t("team.georgiaImageAlt")}/>
          </li>
          <li className='project-team__country'>
            <img className='project-team__country-icon' src={ukraine} alt={t("team.ukraineImageAlt")}/>
          </li>
          <li className='project-team__country'>
            <img className='project-team__country-icon' src={serbia} alt={t("team.serbiaImageAlt")}/>
          </li>
          <li className='project-team__country'>
            <img className='project-team__country-icon' src={poland} alt={t("team.polandImageAlt")}/>
          </li>
          <li className='project-team__country'>
            <img className='project-team__country-icon' src={russia} alt={t("team.russiaImageAlt")}/>
          </li>
          <li className='project-team__country'>
            <img className='project-team__country-icon' src={belarus} alt={t("team.belarusImageAlt")}/>
          </li>
          <li className='project-team__country'>
            <img className='project-team__country-icon' src={thailand}alt={t("team.thailandImageAlt")}/>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default ProjectTeam;