import './NextArrow.css';

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div className='projects-slider__shadow projects-slider__shadow_right'>
      <div
        className='projects-slider__arrow projects-slider__arrow_next'
        style={{ ...style}}
        onClick={onClick}
        >
        <span className='projects-slider__arrow-icon projects-slider__arrow-icon_next'/>
      </div>
    </div>
  );
}

export default NextArrow;