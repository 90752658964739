import './Footer.css';
import logo from '../../images/FooterLogo.svg';
import { useTranslation } from "react-i18next";

function Footer({ handleButtonOpenPopup }) {
  const { t } = useTranslation();

  return (
    <footer className='footer'>
      <div className='footer__logo-container'>
        <img className='footer__logo' src={logo} alt='Logo'/>
        <h2 className='footer__logo-title'>Ninjex Tech</h2>
        <p className='footer__logo-subtitle'>{t("footer.copywriter")}</p>
      </div>
      <ul className='footer__contacts'>
        <li className='footer__contact'>
          <a className='footer__contact-link' href='mailto:hello@ninjex.agency' target="blank">
          hello@ninjex.agency
          </a>
        </li>
        <li className='footer__contact'>
          <a className='footer__contact-link' href='https://t.me/ninjex' target="blank">
            <span className='footer__contact-icon'/>
            @ninjex
          </a>
        </li>
      </ul>
      <button to='/' className='footer__link' onClick={handleButtonOpenPopup}>{t("footer.privacyPolicy")}</button>
    </footer>
  )
}

export default Footer;