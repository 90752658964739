import './ProjectPopup.css';
import { useTranslation } from "react-i18next";

function ProjectPopup({project, isOpen, onClose}) {
  const { t } = useTranslation();

  const handleStartProjectClick = () => {
    onClose()
    document.querySelector('.feedback-form').scrollIntoView( { behavior: "smooth"})
  }

  return (
    <div onClick={e => (e.currentTarget === e.target) && onClose()} className={`project-popup ${isOpen && "project-popup_opened"}`}>
      <div className="project-popup__container">
        <div className="project-popup__header-container">
          <h2 className='project-popup__title'>{project.title}</h2>
          <button className='project-popup__button_close' onClick={onClose}/>
        </div>
        <div className='project-popup__text-container'>
          <div className='project-popup__text'>
            <img src={project.image} alt={project.alt} className='project-popup__image'/>
            <p className='project-popup__paragraph'>{project.subtitle}</p>
          </div>
          <div className='project-popup__fade'></div>
        </div>
        <button className='project-popup__button' onClick={handleStartProjectClick}>{t("projects.startProjectButton")}</button>
      </div>
    </div>
  )
}

export default ProjectPopup;