import projectImage from '../images/project-image.png';

export const projectsEn = [
  {
    id: 1,
    title: 'Neuromorphic computing Blockchain',
    subtitle: 'Fast and efficient neuromorphic distributed computing platform with custom node, GPU miner and highload backend.',
    image: projectImage,
    alt: 'Neuromorphic computing Blockchain',
    tags: ['Blockchain', 'Mining software', 'Security audit', 'Automatic and manual testing'],
  },
  {
    id: 2,
    title: 'Algorithmic stable token',
    subtitle: 'A algorithmic stable token attached to the cost per square meter of real estate in Paris.',
    image: projectImage,
    alt: 'Algorithmic stable token',
    tags: ['Smart contract', 'Contract audit', 'Frontend'],
  },
  {
    id: 3,
    title: 'Web3 wallet app',
    subtitle: 'Secure non-custodial mobile wallet app with ecosystem and web3 browser for Android and IOS.',
    image: projectImage,
    alt: 'Web3 wallet app',
    tags: ['Mobile application', 'Backend', 'UI/UX', 'System design', 'Security audit'],
  },
  {
    id: 4,
    title: 'Reflect token',
    subtitle: 'ERC-20 reflect token with tax mechanism. Interaction with PancakeSwap, UniSwap, Bridge.',
    image: projectImage,
    alt: 'Reflect token',
    tags: ['Smart contract', 'Contract audit', 'System design', 'Automatic and manual testing'],
  },
  {
    id: 5,
    title: 'Crypto wallet',
    subtitle: 'Custodial crypto wallet with cold storage and multichain swaps, supporting unlimited number of users with extra level of security.',
    image: projectImage,
    alt: 'Crypto wallet',
    tags: ['Backend', 'Frontend', 'UI/UX', 'System design', 'Security audit'],
  },
  {
    id: 6,
    title: 'Interested in building something new?',
    subtitle: 'We also want to help you. Fill the form below or message us on Telegram @ninjex',
    image: projectImage,
    alt: 'Interested in building something new?',
    tags: ['Build together'],
  }
];

export const projectsRu = [
  {
    id: 1,
    title: 'Нейроморфные вычисления на блокчейне',
    subtitle: 'Быстрые и эффективные распределенные вычисления с нодой, GPU майнером и высоконагруженным бекендом.',
    image: projectImage,
    alt: 'Нейроморфные вычисления на блокчейне',
    tags: ['Blockchain', 'Mining software', 'Security audit', 'Automatic and manual testing'],
  },
  {
    id: 2,
    title: 'Алгоритмический стейблкоин',
    subtitle: 'Алгоритмический стейблкоин с привязкой цены к стоимости квадратного метра недвижимости в Париже',
    image: projectImage,
    alt: 'Алгоритмический стейблкоин',
    tags: ['Smart contract', 'Contract audit', 'Frontend'],
  },
  {
    id: 3,
    title: 'Web3 кошелек',
    subtitle: 'Защищенный некастодиальный мобильный кошелек с экосистемой и web3 браузером под Android и IOS',
    image: projectImage,
    alt: 'Web3 кошелек',
    tags: ['Mobile applications', 'Backend', 'UI/UX', 'System design', 'Security audit'],
  },
  {
    id: 4,
    title: 'Reflect токен',
    subtitle: 'ERC-20 Reflect токен с комиссионным механизмом, взаимодействующий с  PancakeSwap, UniSwap, Bridge.',
    image: projectImage,
    alt: 'Reflect токен',
    tags: ['Smart contract', 'Contract audit', 'System design', 'Automatic and manual testing'],
  },
  {
    id: 5,
    title: 'Web криптокошелек',
    subtitle: 'Кастодиальный криптокошелек с холодным хранением средств и мультичейн обменами. Поддерживает неограниченное количество пользователей с максимальным уровнем защиты.',
    image: projectImage,
    alt: 'Crypto wallet',
    tags: ['Backend', 'Frontend', 'UI/UX', 'System design', 'Security audit'],
  },
  {
    id: 6,
    title: 'Хотите сделать что-то новое?',
    subtitle: 'Мы хотим помочь вам. Заполните форму ниже или свяжитесь с нами через Телеграм @ninjex',
    image: projectImage,
    alt: 'Хотите сделать что-то новое?',
    tags: ['Build together'],
  }
];