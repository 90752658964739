import './Header.css';
import { useState } from 'react';
import telegramLogo from '../../images/TelegramLogo.svg';
import russia from '../../images/Russia.png';
import britain from '../../images/united-kingdom.png';
import MobileMenu from '../MobileMenu/MobileMenu';
import useScreenWidth from "../../utils/useScreenWidth";
import useScroll from '../../utils/useScroll';
import logo from '../../images/Logo.png';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

function Header() {
  const { t, i18n } = useTranslation();

  const size =  useScreenWidth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [initialLanguage, setInitialLanguage] = useState(localStorage.getItem('i18nextLng'));

  useEffect(() => {
    const language = localStorage.getItem('lang')
    setInitialLanguage(language);
    i18n.changeLanguage(language)
  }, []);

  const changeLanguage = () => {
    if (initialLanguage === 'en') {
      setInitialLanguage('ru')
      i18n.changeLanguage('ru')
      localStorage.setItem('lang', 'ru');
    } else {
      setInitialLanguage('en')
      i18n.changeLanguage('en')
      localStorage.setItem('lang', 'en');
    }
  }

  const mobileMenuClassName = (
    `${isMobileMenuOpen ? 'mobile-menu' : 'mobile-menu_invisible'}`
  );

  function openMobileMenu() {
    setIsMobileMenuOpen(true)
  }

  function closeMobileMenu() {
    setIsMobileMenuOpen(false)
  }

  const navLinks = ["Team", "Service", "Technology", "Projects"];

  const renderNavLink = (content) => {
    const key = `navLinks.${content}`

    const scrollToElement = `.section_${content.toLowerCase()}`
    const handleClickNav = () => {
      document.querySelector(scrollToElement).scrollIntoView( { behavior: "smooth"})
    }

    return (
      <li key={content} className="header__item">
        <button 
          onClick={handleClickNav}
          className="header__link"
        >{t(key)}</button>
      </li>
    )
  }

  const scroll = useScroll()
  
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth"})
  }

  const handleButtonStartProjectClick = () => {
    document.querySelector('.feedback-form').scrollIntoView( { behavior: "smooth"})
  }

  return (
    <header className='header'>
      <div className='header__wrapper'>
        <div className='header__nav-container'>
          {size.width>992 ?
            <nav className="header__nav">
              <ul className="header__links" >
                {navLinks.map(nav => renderNavLink(nav))}
              </ul>
            </nav>
            : <button className='heder__mobile-menu' onClick={openMobileMenu}/>
          }
          {scroll !== 0 &&
            <div className='header__logo-container' onClick={handleLogoClick} >
              <img src={logo} alt='Logo' className='header__logo'/>
              {size.width<992 && <h2 className='header__logo-title'>Ninjex Tech</h2>}
            </div>
          }
        </div>
        <div className="header__container">
          <a className="header__contact" href='https://t.me/ninjex' target="blank">
            <img className="header__contact-img" src={telegramLogo} alt='Telegram'/>
          </a>
          <button className="header__select-language" onClick={changeLanguage}>
            <img src={initialLanguage === 'en' ? russia : britain} className="header__select-language-icon" alt='Language'/>
            <p className="header__select-language-value">{initialLanguage === 'en' ? 'RU' : 'EN'}</p>
          </button>
          {scroll !== 0 && size.width>992 &&
            <button className='header__start-project' onClick={handleButtonStartProjectClick}>{t("header.startProjectButton")}</button>
          }
        </div>
      </div>
      <MobileMenu
        className={mobileMenuClassName}
        onClose={closeMobileMenu}
      />
    </header>
  )
}

export default Header;