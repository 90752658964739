import Main from '../Main/Main';
import './App.css';
import { useTranslation } from "react-i18next";

function App() {
  const {t, i18n} = useTranslation()

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  return (
    <div className="page">
      <Main />
    </div>
  );
}

export default App;
