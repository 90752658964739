import './SliderBlock.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import NextArrow from './NextArrow/NextArrow';
import PrevArrow from './PrevArrow/PrevArrow';
import useScreenWidth from '../../../utils/useScreenWidth';
import SliderItem from './SliderItem/SliderItem';
import { projectsRu, projectsEn } from '../../../utils/constants';
import { useState } from 'react';
import ProjectPopup from './ProjectPopup/ProjectPopup';

function SliderBlock() {
  const size =  useScreenWidth();

  const language = localStorage.getItem('i18nextLng')
  const projects = (language === "ru" ? projectsRu : projectsEn)

  const [isPopupProjectOpen, setIsPopupProjectOpen] = useState(false);
  const [initialProject, setInitialProject] = useState({})

  const handleButtonOpenProjectPopup = (e) => {
    //setIsPopupProjectOpen(true)
  }

  const closeProjectPopup = () => {
    setIsPopupProjectOpen(false)
  }

  const centerPadding = (
    `${size.width > 992 ? '20%' : '5%'}`
  )

  const settings = {
    className: 'projects-slider',
    dots: false,
    // infinite: false,
    speed: 500,
    centerPadding: centerPadding,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <Slider {...settings}>
        {projects.map((project, index) =>
          <SliderItem
            project={project}
            key={index}
            setInitialProject={setInitialProject}
            openPopup={handleButtonOpenProjectPopup}
          />
        )}
      </Slider>
      <ProjectPopup
        project={initialProject}
        onClose={closeProjectPopup}
        isOpen={isPopupProjectOpen}
      />
    </>
  )
}

export default SliderBlock;