import { useState } from 'react';
import './Service.css';
import { useTranslation } from "react-i18next";

function Service() {
  const { t } = useTranslation();

  const [isOpenMoreServices, setIsOpenMoreServices] = useState(false);

  const moreServicesClassName = (
    `${isOpenMoreServices ? 'service__table' : 'service__table_invisible'}`
  )

  const arrowClassName = (
    `${isOpenMoreServices ? 'service__button-icon service__button-icon_close' : 'service__button-icon'}`
  )

  const onViewMoreServicesClick = () => {
    setIsOpenMoreServices(!isOpenMoreServices)
  }

  return (
    <section className='service section_service'>
      <span className='service__object service__object_left'/>
      <span className='service__object service__object_right'/>
      <div className='service__wrapper'>
        <h2 className='service__title'>{t("service.title")}</h2>
        <ul className='service__table'>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_backend'/>
            <p className='service__table-text'>{t("service.backend")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_mobile-app'/>
            <p className='service__table-text'>{t("service.mobileApplications")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_smart-conracts'/>
            <p className='service__table-text'>{t("service.smartContract")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_ui-ux'/>
            <p className='service__table-text'>{t("service.frontend")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_audit-contract'/>
            <p className='service__table-text'>{t("service.auditSmartContracts")}</p>
          </li>
        </ul>
        <ul className={moreServicesClassName}>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_ui-ux'/>
            <p className='service__table-text'>{t("service.UI/UX")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_crm-systems'/>
            <p className='service__table-text'>{t("service.crmSystem")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_mining-software'/>
            <p className='service__table-text'>{t("service.miningSoftware")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_gameFi'/>
            <p className='service__table-text'>{t("service.gameFi")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_web3Consulting'/>
            <p className='service__table-text'>{t("service.web3Consulting")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_blockchains'/>
            <p className='service__table-text'>{t("service.blockchains")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_business-analytics'/>
            <p className='service__table-text'>{t("service.businessAnalytics")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_system-design'/>
            <p className='service__table-text'>{t("service.systemDesign")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_security-audit'/>
            <p className='service__table-text'>{t("service.auditApps")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_tokenomics'/>
            <p className='service__table-text'>{t("service.tokenomics")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_testing'/>
            <p className='service__table-text'>{t("service.tests")}</p>
          </li>
          <li className='service__table-item'>
            <span className='service__table-icon service__table-icon_sap'/>
            <p className='service__table-text'>{t("service.SAPintegration")}</p>
          </li>
        </ul>
        <button className='service__button' onClick={onViewMoreServicesClick}>{isOpenMoreServices ? t("service.hide") : t("service.loadMore")} <span className={arrowClassName}/></button>
      </div>
    </section>
  )
}

export default Service;