import './Main.css';
import { useState } from 'react';
import Header from '../Header/Header';
import About from '../About/About';
import ProjectTeam from '../ProjectTeam/ProjectTeam'
import Service from '../Service/Service';
import Technologies from '../Technologies/Technologies';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import Footer from '../Footer/Footer';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import Projects from '../Projects/Projects';

function Main() {
  const [isPopupPrivacyPolicyOpen, setIsPopupPrivacyPolicyOpen] = useState(false);
  
  const handleButtonOpenPopup = (e) => {
    setIsPopupPrivacyPolicyOpen(true)
  }

  const closePrivacyPolicyPopup = () => {
    setIsPopupPrivacyPolicyOpen(false)
  }

  return (
    <div className="main">
      <div>
      <Header />
      <main>
        <About />
        <ProjectTeam />
        <Service />
        <Technologies />
        <Projects />
        <FeedbackForm />
        <PrivacyPolicy onClose={closePrivacyPolicyPopup} isOpen={isPopupPrivacyPolicyOpen}/>
      </main>
      <Footer handleButtonOpenPopup={handleButtonOpenPopup}/>
      </div>
    </div>
  )
}

export default Main;