import './PrevArrow.css';

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div className='projects-slider__shadow projects-slider__shadow_left'>
      <div
        className='projects-slider__arrow projects-slider__arrow_prev'
        style={{ ...style }}
        onClick={onClick}
        >
        <span className='projects-slider__arrow-icon projects-slider__arrow-icon_prev'/>
      </div>
    </div>
  );
}

export default PrevArrow;