import './MobileMenu.css';
import { useTranslation } from "react-i18next";

function MobileMenu({ onClose, className }) {
  const { t } = useTranslation();

  const navLinks = ["Team", "Service", "Technology", "Projects"];

  const renderNavLink = (content) => {
    const key = `navLinks.${content}`

    const scrollToElement = `.section_${content.toLowerCase()}`
    
    const handleClickNav = () => {
      document.querySelector(scrollToElement).scrollIntoView( { behavior: "smooth"})
      onClose()
    }

    return (
      <li key={content} className="mobile-menu__item">
        <button 
          onClick={handleClickNav}
          className="mobile-menu__link"
        >{t(key)}</button>
      </li>
    )
  }

  const hadleButtonStartProjectClick = () => {
    document.querySelector('.feedback-form').scrollIntoView( { behavior: "smooth"})
    onClose()
  }

  return (
    <div className={className}>
      <button className='mobile-menu__button-close' onClick={onClose}/>
      <nav className="mobile-menu__nav">
        <ul className="mobile-menu__nav" >
          {navLinks.map(nav => renderNavLink(nav))}
        </ul>
        <button className='mobile-menu__start-project' onClick={hadleButtonStartProjectClick}>{t("header.startProjectButton")}</button>
      </nav>
    </div>
  )
}

export default MobileMenu;