import './SubmitPopup.css';
import { useTranslation } from "react-i18next";

function SubmitPopup({isOpen, onClose }) {
  const { t } = useTranslation();

  return (
    <div onClick={e => (e.currentTarget === e.target) && onClose()} className={`submit-popup ${isOpen && "submit-popup_opened"}`}>
      <div className="submit-popup__container">
        <button className="submit-popup__button-close" onClick={onClose}/>
        <h3 className="submit-popup__title">
            {t("submitPopup.title")}
        </h3>
        <button className='submit-popup__button' onClick={onClose}>{t("submitPopup.button")}</button>
      </div>
    </div>
  )
}

export default SubmitPopup;